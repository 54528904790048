$black: rgba(15,15,15,0.96);
$black:red;
//$black10t:rgba(10,10,10, .5);
//$black10t:rgba(10,10,10,.7);
$black10t:rgba(10,10,10,.9);
$black30:rgb(30,30,30);
$black35:rgb(35,35,35);
$black40:rgb(40,40,40);
$black50: rgb(50,50,50);
$black60: rgb(60,60,60);
$black50light:rgba(50,50,50,.5);
$border-radius: 5px;
$gap: 5px;
$margin: 5px;
$padding:5px;
$red: rgb(255,0,76); //249,38,114
//$green:rgb(166, 226, 46);
$green:  rgb(109, 161, 72);
$blue: rgba(127, 255, 255, 1);
$transparent: rgba(255, 255, 255, 0.2);
$white: rgb(200,200,200);
$margin-mobile:2px;
$filter: drop-shadow(8px 8px 10px $blue);

@import 'settings';

body{
    grid-template-columns: 0px 1fr 0px;
}

header{
    grid-row:1;
    align-items: center;
    justify-content: center;
    gap: $gap*2;
    margin-top:$margin*2;
}

header a{
    color: gray;
    align-self: flex-end; 
}

header div{
    display:flex;
    gap: $gap*2;
    flex-direction:column;
}
header span{
    color:$red;
}
header fieldset{
    display: flex;
    gap: $gap*2;
    padding: 0;	     
}

header form{
    display:flex;
    gap: $gap*2;
}

header p{
    display:flex;
    gap:$gap*2;
    align-self: flex-end;
}

h1{
    font-weight:600;
    font-size: 200%;
    text-shadow: -2px 2px 10px $blue, 2px -2px 10px $blue;
    color:white;
    align-self:center;
}

main{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

main form{
    display:flex;
    flex-direction: column;
    gap: $gap*2;
    flex-wrap: wrap;
    background-color: transparent;
}

main form fieldset { 
    display: flex;
    flex-direction: column;
    gap: $gap*2;
}

main form legend { 
    font-size: xx-large;
    margin-bottom: 10px;
}

main figure{
display:flex;
}

@media (max-width: 600px) {
    header{
	display:flex;
	flex-direction: column;
    }
    
    header form{
	flex-direction: column;	
    }
    
    header fieldset{
	flex-direction: column;
    }
    
    main{
	flex-direction: column;	
    }

    main figure{
	//width:150px;
	//height: 150px;
	display:none;
    }
}
